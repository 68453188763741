<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title class="headline">
        <h3>¿Desea crear una nueva semana?</h3>
        <small>Esto generará una nueva semana en la base de datos.</small>
      </v-card-title>
      <v-card-actions>
        <v-btn
          color="green darken-1"
          text
          @click="dialog = false"
        >
          {{ $t('app.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary dark"
          @click="open"
        >
          {{ $t('app.buttons.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'WeekAddAlert',
    data() {
      return {
        dialog: false,
        week: {}
      }
    },
    mounted () {
      EventBus.$on('weeks-add-alert', (item) => {
        this.training = item
        this.dialog = true
      })
    },
    methods: {
      async open () {
        this.dialog = false
        this.toggleLoader()

        await this.$http.post(route("weeks"), {
          data: {
            name: `Semana ${this.training.weeks.length + 1}`
          }
        })
          .then(async response => {
            if (response.body.data) {
              let week = response.body.data
              week.days = []
              this.training.weeks.push(week)

              await this.$http.put(route(`trainings/${this.training.id}`), {
                data: this.training
              }).then(response => {
                EventBus.$emit('reload-items-weeks-edit', this.training)
                EventBus.$emit('weeks-edit', {
                  week,
                  training: this.training
                })
              }, error => this.processError(error))
            } else {
              this.processError(response)
            }
          }, error => {
            this.processError(error)
          })

        this.toggleLoader()
      },
    },
  }
</script>

<style scoped>

</style>
